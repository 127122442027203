import { t } from "i18next";
import Calendar from "views/pages/Calendar.js";
import Charts from "views/pages/Charts.js";
import Widgets from "views/pages/Widgets.js";
import Lock from "views/pages/auth/Lock.js";
import Buttons from "views/pages/components/Buttons.js";
import Cards from "views/pages/components/Cards.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Notifications from "views/pages/components/Notifications.js";
import Typography from "views/pages/components/Typography.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Timeline from "views/pages/examples/Timeline.js";
import Components from "views/pages/forms/Components.js";
import Elements from "views/pages/forms/Elements.js";
import Validation from "views/pages/forms/Validation.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import i18n from "./i18n";
import AICMinibusinesses from "./views/pages/AICMinibusiness/AICMinibusiness";
import AreaSection from "./views/pages/AreaSection/AreaSection";
import FactoryBusinessUnit from "./views/pages/FactoryBusinessUnits/FactoryBusinessUnit";
import Idea from "./views/pages/ideas/Idea";
import IdeaImport from "./views/pages/ideas/IdeaImport";
import ImportedKaizenTemplate from "./views/pages/kaizenTemplate/ImportedKaizenTemplate";
import KaizenTemplate from "./views/pages/kaizenTemplate/KaizenTemplate";
import User from "./views/pages/users/User";
import UploadRewardAndRecognition from "./views/pages/UploadRewardAndRecognition/UploadRewardAndRecognition";
import UploadEmployeeCodeSheet from "./views/pages/UploadEmployeeCodeSheet/UploadEmployeeCodeSheet";
import UploadVideo from "./views/pages/UploadVideo/UploadVideo";
import UploadBackupFile from "./views/pages/ideas/UploadBackupFile/UploadBackupFile";

const tempRoutes =
  process.env.NODE_ENV !== "production"
    ? [
        {
          collapse: true,
          name: "Components",
          icon: "ni ni-ui-04 text-info",
          state: "componentsCollapse",
          views: [
            {
              path: "/buttons",
              name: "Buttons",
              miniName: "B",
              component: Buttons,
              layout: "/admin",
            },
            {
              path: "/cards",
              name: "Cards",
              miniName: "C",
              component: Cards,
              layout: "/admin",
            },
            {
              path: "/grid",
              name: "Grid",
              miniName: "G",
              component: Grid,
              layout: "/admin",
            },
            {
              path: "/notifications",
              name: "Notifications",
              miniName: "N",
              component: Notifications,
              layout: "/admin",
            },
            {
              path: "/icons",
              name: "Icons",
              miniName: "I",
              component: Icons,
              layout: "/admin",
            },
            {
              path: "/typography",
              name: "Typography",
              miniName: "T",
              component: Typography,
              layout: "/admin",
            },
            {
              collapse: true,
              name: "Multi Level",
              miniName: "M",
              state: "multiCollapse",
              views: [
                {
                  path: "#pablo",
                  name: "Third level menu",
                  component: () => {},
                  layout: "/",
                },
                {
                  path: "#pablo",
                  name: "Just another link",
                  component: () => {},
                  layout: "/",
                },
                {
                  path: "#pablo",
                  name: "One last link",
                  component: () => {},
                  layout: "/",
                },
              ],
            },
          ],
        },
        {
          collapse: true,
          name: "Forms",
          icon: "ni ni-single-copy-04 text-pink",
          state: "formsCollapse",
          views: [
            {
              path: "/elements",
              name: "Elements",
              miniName: "E",
              component: Elements,
              layout: "/admin",
            },
            {
              path: "/components",
              name: "Components",
              miniName: "C",
              component: Components,
              layout: "/admin",
            },
            {
              path: "/validation",
              name: "Validation",
              miniName: "V",
              component: Validation,
              layout: "/admin",
            },
          ],
        },
        {
          collapse: true,
          name: "Tables",
          icon: "ni ni-align-left-2 text-default",
          state: "tablesCollapse",
          views: [
            {
              path: "/tables",
              name: "Tables",
              miniName: "T",
              component: Tables,
              layout: "/admin",
            },
            {
              path: "/sortable",
              name: "Sortable",
              miniName: "S",
              component: Sortable,
              layout: "/admin",
            },
            {
              path: "/react-bs-table",
              name: "React BS Tables",
              miniName: "RBT",
              component: ReactBSTables,
              layout: "/admin",
            },
          ],
        },
        {
          path: "/widgets",
          name: "Widgets",
          icon: "ni ni-archive-2 text-green",
          component: Widgets,
          layout: "/admin",
        },
        {
          path: "/charts",
          name: "Charts",
          icon: "ni ni-chart-pie-35 text-info",
          component: Charts,
          layout: "/admin",
        },
        {
          path: "/calendar",
          name: "Calendar",
          icon: "ni ni-calendar-grid-58 text-red",
          component: Calendar,
          layout: "/admin",
        },
        {
          collapse: true,
          name: "Examples",
          icon: "ni ni-ungroup text-orange",
          state: "examplesCollapse",
          views: [
            {
              path: "/pricing",
              name: "Pricing",
              miniName: "P",
              component: Pricing,
              layout: "/auth",
            },
            {
              path: "/lock",
              name: "Lock",
              miniName: "L",
              component: Lock,
              layout: "/auth",
            },
            {
              path: "/timeline",
              name: "Timeline",
              miniName: "T",
              component: Timeline,
              layout: "/admin",
            },

            {
              path: "/rtl-support",
              name: "RTL Support",
              miniName: "RS",
              component: RTLSupport,
              layout: "/rtl",
            },
          ],
        },
      ]
    : [];

const adminRoutes = [
  {
    icon: "ni ni-align-center text-primary",
    path: "/user",
    name: i18n.t("users"),
    miniName: "U",
    component: User,
    layout: "/admin",
  },
  {
    collapse: true,
    name: t("masterData"),
    icon: "ni ni-align-center text-primary",
    state: "masterDataCollapse",
    views: [
      {
        path: "/factoryBusinessUnit",
        name: i18n.t("factoryBusinessUnit"),
        miniName: "F",
        component: FactoryBusinessUnit,
        layout: "/admin",
      },
      {
        path: "/areaSection",
        name: i18n.t("areaSection"),
        miniName: "A",
        component: AreaSection,
        layout: "/admin",
      },
      {
        path: "/AICMiniBusiness",
        name: i18n.t("AICMiniBusiness"),
        miniName: "A",
        component: AICMinibusinesses,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: t("submitted"),
    icon: "ni ni-diamond text-primary",
    state: "submittedCollapse",
    views: [
      {
        path: "/idea",
        name: i18n.t("submittedIdeas"),
        miniName: "I",
        component: Idea,
        layout: "/admin",
      },
      {
        path: "/kaizenTemplate",
        name: t("submittedIdeaBank"),
        miniName: "SIB",
        component: KaizenTemplate,
        layout: "/admin",
      },
      {
        path: "/uploadBackupFile",
        name: t("UploadBackupFile"),
        miniName: "SIB",
        component: UploadBackupFile,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: t("imported"),
    icon: "ni ni-diamond text-primary",
    state: "importedCollapse",
    views: [
      {
        path: "/imported-idea",
        name: i18n.t("importedIdeas"),
        miniName: "I",
        component: IdeaImport,
        layout: "/admin",
      },

      {
        path: "/kaizenTemplateImported",
        name: t("importedIdeaBank"),
        miniName: "IIB",
        component: ImportedKaizenTemplate,
        layout: "/admin",
      },
    ],
  },
  {
    icon: "ni ni-cloud-upload-96 text-primary",
    path: "/reward-and-recognition",
    name: i18n.t("R&R"),
    miniName: "U",
    component: UploadRewardAndRecognition,
    layout: "/admin",
  },
  {
    icon: "ni ni-cloud-upload-96 text-primary",
    path: "/employee-code-sheet",
    name: i18n.t("UploadEmpoloyeeCodeSheet"),
    miniName: "U",
    component: UploadEmployeeCodeSheet,
    layout: "/admin",
  },
  {
    icon: "ni ni-cloud-upload-96 text-primary",
    path: "/upload-video",
    name: i18n.t("UploadVideo"),
    miniName: "U",
    component: UploadVideo,
    layout: "/admin",
  },
];
const appRoutes = [];
const userType = JSON.parse(localStorage.getItem("user"))?.userType;
if (userType === "ADMIN" || userType === "CONSULTANT") {
  appRoutes.push(...adminRoutes);
}
const routes = [
  {
    icon: "ni ni-shop text-primary",
    path: "/dashboard",
    name: i18n.t("summary"),
    miniName: "D",
    component: Dashboard,
    layout: "/admin",
  },
  ...appRoutes,
  {
    icon: "ni ni-single-02 text-primary",
    path: "/profile",
    name: i18n.t("profile"),
    miniName: "P",
    component: Profile,
    layout: "/admin",
  },

  //////////////////////
  ...tempRoutes,
];

export default routes;
