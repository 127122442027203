/*eslint-disable*/
import React from "react";
// react library for routing
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { connect } from "react-redux";
import { selectCurrentUser } from "store/selectors/user.selectors";
import { createStructuredSelector } from "reselect";
import EditUser from "../views/pages/users/EditUser";
import EditIdea from "../views/pages/ideas/EditIdea";
import AddScore from "../views/pages/ideas/AddScore";
import AddCompany from "../views/pages/companies/AddCompany";
import EditCompany from "../views/pages/companies/EditCompany";
import AddFactory from "../views/pages/factories/AddFactory";
import EditFactory from "../views/pages/factories/EditFactory";
import AddMiniBusiness from "../views/pages/miniBusiness/AddMiniBusiness";
import EditMiniBusiness from "../views/pages/miniBusiness/EditMiniBusiness";
import AddDepartment from "../views/pages/department/AddDepartment";
import EditDepartment from "../views/pages/department/EditDepartment";
import AddStage from "../views/pages/stage/AddStage";
import EditStage from "../views/pages/stage/EditStage";
import AddFirstCut from "../views/pages/firstCut/AddFirstCut";
import EditFirstCut from "../views/pages/firstCut/EditFirstCut";
import AddSecondCut from "../views/pages/secondCut/AddSecondCut";
import EditSecondCut from "../views/pages/secondCut/EditSecondCut";
import EditIdeaFields from "../views/pages/ideas/EditIdeaFields";
import EditOPLtable from "../views/pages/ideas/EditOPLtable";
import AddFactoryBusinessUnit from "../views/pages/FactoryBusinessUnits/AddFactoryBusinessUnit";
import EditFactoryBusinessUnit from "../views/pages/FactoryBusinessUnits/EditfactoryBusinessUnit";
import AddAreaSection from "../views/pages/AreaSection/AddAreaSection";
import EditAreaSection from "../views/pages/AreaSection/EditAreaSection";
import AddAICMiniBusiness from "../views/pages/AICMinibusiness/AddAICMiniBusiness";
import EditAICMiniBusiness from "../views/pages/AICMinibusiness/EditAICMiniBusiness";

function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        // rtlActive={user.defaultLang === "en" ? false : true}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: require("assets/img/brand/CloroxLogo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          {/* user */}
          <Route path="/admin/editUser/:id" component={EditUser} />
          {/* idea */}
          <Route path="/admin/editIdea/:id" component={EditIdea} />
          <Route path="/admin/editIdeaFields/:id" component={EditIdeaFields} />
          <Route path="/admin/editOPLtable/:id" component={EditOPLtable} />
          {/* score */}
          <Route path="/admin/addScore/:id" component={AddScore} />
          {/* company */}
          <Route path="/admin/addCompany" component={AddCompany} />
          <Route path="/admin/editCompany/:id" component={EditCompany} />
          {/* factoryBusinessUnit */}
          <Route
            path="/admin/addFactoryBusinessUnit"
            component={AddFactoryBusinessUnit}
          />
          <Route
            path="/admin/editFactoryBusinessUnit/:id"
            component={EditFactoryBusinessUnit}
          />
          {/* areaSection */}
          <Route path="/admin/addAreaSection" component={AddAreaSection} />
          <Route
            path="/admin/editAreaSection/:id"
            component={EditAreaSection}
          />
          {/* AICMiniBusiness */}
          <Route
            path="/admin/addAICMiniBusiness"
            component={AddAICMiniBusiness}
          />
          <Route
            path="/admin/editAICMiniBusiness/:id"
            component={EditAICMiniBusiness}
          />
          {/* factory */}
          <Route path="/admin/addFactory" component={AddFactory} />
          <Route path="/admin/editFactory/:id" component={EditFactory} />
          {/* miniBusinesses */}
          <Route path="/admin/addMiniBusiness" component={AddMiniBusiness} />
          <Route
            path="/admin/editMiniBusiness/:id"
            component={EditMiniBusiness}
          />
          {/* departments */}
          <Route path="/admin/addDepartment" component={AddDepartment} />
          <Route path="/admin/editDepartment/:id" component={EditDepartment} />
          {/* stages */}
          <Route path="/admin/addStage" component={AddStage} />
          <Route path="/admin/editStage/:id" component={EditStage} />
          {/* first cuts */}
          <Route path="/admin/addFirstCut" component={AddFirstCut} />
          <Route path="/admin/editFirstCut/:id" component={EditFirstCut} />
          {/* second cuts */}
          <Route path="/admin/addSecondCut" component={AddSecondCut} />
          <Route path="/admin/editSecondCut/:id" component={EditSecondCut} />

          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
        {/* <AdminFooter /> */}
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(selectCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
